<template>
  <v-data-table
    :headers="headers"
    :loading="loading"
    :items="filterItems(items)"
    :expanded.sync="expanded"
    :items-per-page="size"
    :page="page"
    :server-items-length="count"
    :footer-props="{'items-per-page-options': [10, 20, 50, 100, 500]}"
    :item-class="(item) => isInvestmentDisabled(item) ? 'disabled' : ''"
    :short="short"
    :hide-default-footer="short"
    show-expand
    class="elevation-0 investment-list"
    @update:items-per-page="sizeChanged"
    @update:page="pageChanged"
    @item-expanded="expandChange"
  >
    <template #[`header.loan_amount`]="{ header }">
      {{ header.text }}
      <v-tooltip bottom max-width="400">
        <template #activator="{ on }">
          <v-icon slot="activator" dense size="12" color="primary" dark v-on="on">
            mdi-information-outline
          </v-icon>
        </template>
        <span>{{ $t('loanDetails.investmentList.loanAmountDescription') }}</span>
      </v-tooltip>
    </template>
    <template #[`header.amount`]="{ header }">
      {{ header.text }}
      <v-tooltip bottom max-width="400">
        <template #activator="{ on }">
          <v-icon slot="activator" dense size="12" color="primary" dark v-on="on">
            mdi-information-outline
          </v-icon>
        </template>
        <template v-if="short">{{ $t('loanDetails.investmentList.amountToBeInvestedDescription') }}</template>
        <template v-else>{{ $t('loanDetails.investmentList.investedAmountDescription') }}</template>
      </v-tooltip>
    </template>
    <template #[`header.loan.period`]="{ header }">
      {{ header.text }}
      <v-tooltip bottom max-width="400">
        <template #activator="{ on }">
          <v-icon slot="activator" dense size="12" color="primary" dark v-on="on">
            mdi-information-outline
          </v-icon>
        </template>
        <span>{{ $t('loanDetails.investmentList.loanTermsDescription') }}</span>
      </v-tooltip>
    </template>
    <template #[`header.payments_in_recovery`]="{ header }">
      {{ header.text }}
      <v-tooltip bottom max-width="400">
        <template #activator="{ on }">
          <v-icon slot="activator" dense size="12" color="primary" dark v-on="on">
            mdi-information-outline
          </v-icon>
        </template>
        <span>{{ $t('loanDetails.investmentList.paymentsInRecoveryDescription') }}</span>
      </v-tooltip>
    </template>
    <template #[`item.next_payment`]="{ item }">
      <v-list-item two-line>
        <span v-if="item.next_payment">
          <BaseMoney :amount="item.next_payment.amount"/>&nbsp;<BaseDate :date="item.next_payment.due_date"/>
        </span>
        <span v-else>
          {{ $t('loanDetails.investmentList.NA') }}
        </span>
      </v-list-item>
    </template>

    <template #[`item.loan.buyback_guarantee`]="{ item }">
      <v-icon :title="item.loan.buyback_guarantee ? $t('loanDetails.investmentList.buybackGuaranteeYes') : $t('loanDetails.investmentList.buybackGuaranteeNo')">
        {{ item.loan.buyback_guarantee ? 'mdi-shield' : 'mdi-shield-off' }}
      </v-icon>
    </template>
    <template #[`item.loan.period`]="{ item }">
      {{ formattedPeriod(item.loan.value_date, item.loan.maturity_date) || $t('loanDetails.investmentList.NA') }}
      <template v-if="new Date(item.loan.maturity_date) > startOfDay(new Date())">
        ({{ formattedPeriod(new Date(), item.loan.maturity_date) }})
      </template>
    </template>
    <template #[`item.loan_amount`]="{ item }">
      <BaseMoney :amount="item.loan.amount"/>
    </template>
    <template #[`item.amount`]="{ item }">
      <BaseMoney :amount="item.amount"/>
    </template>
    <template #[`item.outstanding_principal`]="{ item }">
      <BaseMoney :amount="item.outstanding_principal"/>
    </template>
    <template #[`item.returned_principal`]="{ item }">
      <BaseMoney :amount="item.returned_principal"/>
    </template>
    <template #[`item.earned_interest`]="{ item }">
      <BaseMoney :amount="item.earned_interest"/>
    </template>
    <template #[`item.autoinvest_strategy`]="{ item }">
      <div :style="{'max-width': '10rem'}">
        <BaseAutoInvestStrategyChip v-if="item.autoinvest_strategy" :strategy="item.autoinvest_strategy"/>
        <template v-else>–</template>
      </div>
    </template>
    <template #[`item.loan.reference_number`]="{ item }">
      <div>{{ item.loan.reference_number }}</div>
      <BaseLoanOriginatorChip :loan-originator="item.loan.loan_originator"/>
    </template>
    <template #[`item.investor_interest`]="{ item }">
      <BasePercent :amount="item.investor_interest || item.loan.investor_interest"/>
    </template>
    <template #[`item.loan.country`]="{ item }">
      <BaseCountry :country="item.loan.country"/>
    </template>
    <template #[`item.start_date`]="{ item }">
      <BaseDate :date="item.start_date"/>
    </template>
    <template #[`item.actions`]="{ item }">
      <div v-if="showSell && $auth.user().is_beta_tester">
        <v-menu
          v-if="showSellButton(item)"
          v-model="menu[item.id]"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-btn
              small
              dark
              v-bind="attrs"
              v-on="on"
            >
              {{ $t('startSelling.sell') }}
            </v-btn>
          </template>
          <StartSelling :investment="item" @close="menu[item.id] = false" @success="investmentChanged"/>
        </v-menu>
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-fab-transition>
              <v-icon
                v-if="!showSellButton(item) && item.secondary_market_entry"
                color="success"
                v-bind="attrs"
                v-on="on"
              >
                mdi-briefcase-check
              </v-icon>
            </v-fab-transition>
          </template>
          <span v-text="$t('startSelling.youHaveAlreadySold')"/>
        </v-tooltip>
      </div>
    </template>
    <template #expanded-item="{ headers: expandedHeaders, item }">
      <td class="px-0" :colspan="expandedHeaders.length">
        <v-expand-transition>
          <LoanDetail v-show="showing[item.id]" :loan="item.loan">
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #details="{ loan, loading }">
              <v-list>
                <v-row>
                  <v-col cols="12" md="6" lg="4">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('loanDetails.investmentList.investmentId') }}</v-list-item-title>
                        <BaseSubtitle :loading="loading">{{ item.reference_number || $t('loanDetails.investmentList.NA') }}</BaseSubtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('loanDetails.investmentList.loanIssueDate') }}</v-list-item-title>
                        <BaseSubtitle :loading="loading"><BaseDate :date="loan.value_date" popup="left"/></BaseSubtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('loanDetails.investmentList.loanListedDate') }}</v-list-item-title>
                        <BaseSubtitle :loading="loading"><BaseDate :date="loan.publishing_date" popup="left"/></BaseSubtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('loanDetails.investmentList.loanEndDate') }}</v-list-item-title>
                        <BaseSubtitle :loading="loading"><BaseDate :date="loan.maturity_date"/></BaseSubtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('loanDetails.investmentList.loanStatus') }}</v-list-item-title>
                        <BaseSubtitle :loading="loading">{{ loan.sub_status ? $t(`loanDetails.statuses.${loan.sub_status}`) : $t('loanDetails.investmentList.NA') }}</BaseSubtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('loanDetails.investmentList.loanTypePurpose') }}</v-list-item-title>
                        <BaseSubtitle :loading="loading">{{ loan.loan_type ? $t(`loanDetails.loanTypes.${loan.loan_type}`) : $t('loanDetails.investmentList.NA') }}</BaseSubtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('loanDetails.isScheduleExtendable') }}
                          <v-tooltip slot="append-outer" bottom max-width="400">
                            <template #activator="{ on }">
                              <v-icon slot="activator" dense color="primary" dark v-on="on">
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ $t('loanDetails.isScheduleExtendableDescription') }}</span>
                          </v-tooltip>
                        </v-list-item-title>
                        <BaseSubtitle :loading="loading">{{ loan.schedule_extendable? $t('loanDetails.investmentList.yes'): $t('loanDetails.investmentList.no') }}</BaseSubtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('loanDetails.loanScheduleExtension') }}
                          <v-tooltip slot="append-outer" bottom max-width="400">
                            <template #activator="{ on }">
                              <v-icon slot="activator" dense color="primary" dark v-on="on">
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ $t('loanDetails.loanScheduleExtensionDescription') }}</span>
                          </v-tooltip>
                        </v-list-item-title>
                        <BaseSubtitle :loading="loading">{{ loan.is_schedule_extended? $t('loanDetails.investmentList.yes'): $t('loanDetails.investmentList.no') }}</BaseSubtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('loanDetails.riskRating') }}
                          <v-tooltip slot="append-outer" bottom max-width="400">
                            <template #activator="{ on }">
                              <v-icon slot="activator" dense color="primary" dark v-on="on">
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ $t('loanDetails.riskRatingDescription') }}</span>
                          </v-tooltip>
                        </v-list-item-title>
                        <BaseSubtitle :loading="loading">{{ loan.risk_rating || $t('loanDetails.investmentList.NA') }}</BaseSubtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('loanDetails.investmentList.buybackObligation') }}
                          <v-tooltip slot="append-outer" bottom max-width="400">
                            <template #activator="{ on }">
                              <v-icon slot="activator" dense color="primary" dark v-on="on">
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ $t('loanDetails.investmentList.buybackObligationDescription') }}</span>
                          </v-tooltip>
                        </v-list-item-title>
                        <BaseSubtitle :loading="loading">
                          <v-icon
                            small
                            :title="loan.buyback_guarantee ? $t('loanDetails.investmentList.buybackObligation') : $t('loanDetails.investmentList.noBuybackObligation')"
                          >
                            {{ loan.buyback_guarantee ? 'mdi-shield' : 'mdi-shield-off' }}
                          </v-icon>
                        </BaseSubtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('loanDetails.investmentList.buybackInitiated') }}
                          <v-tooltip slot="append-outer" bottom max-width="400">
                            <template #activator="{ on }">
                              <v-icon slot="activator" dense color="primary" dark v-on="on">
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ $t('loanDetails.investmentList.buybackInitiatedDescription') }}</span>
                          </v-tooltip>
                        </v-list-item-title>
                        <BaseSubtitle :loading="loading"><BaseDate :date="loan.buyback_start_date" popup="left"/></BaseSubtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('loanDetails.investmentList.loanOriginatorSkinInTheGame') }}
                          <v-tooltip bottom max-width="400">
                            <template #activator="{ on }">
                              <v-icon slot="activator" color="primary"
                                      dark v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>
                              {{ $t('loanDetails.investmentList.loanOriginatorSkinInTheGameDescription') }}
                            </span>
                          </v-tooltip>
                        </v-list-item-title>
                        <BaseSubtitle :loading="loading"><BasePercent :amount="loan.skin_in_the_game"/></BaseSubtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('loanDetails.borrowerAprcPercent') }}
                          <v-tooltip slot="append-outer" bottom max-width="400">
                            <template #activator="{ on }">
                              <v-icon slot="activator" dense color="primary" dark v-on="on">
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ $t('loanDetails.borrowerAprcPercentDescription') }}</span>
                          </v-tooltip>
                        </v-list-item-title>
                        <BaseSubtitle :loading="loading"><BasePercent :amount="loan.aprc"/></BaseSubtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('loanDetails.borrowerInterestRatePercent') }}
                          <v-tooltip slot="append-outer" bottom max-width="400">
                            <template #activator="{ on }">
                              <v-icon slot="activator" dense color="primary" dark v-on="on">
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ $t('loanDetails.borrowerInterestRatePercentDescription') }}</span>
                          </v-tooltip>
                        </v-list-item-title>
                        <BaseSubtitle :loading="loading"><BasePercent :amount="loan.borrower_interest"/></BaseSubtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('loanDetails.investmentList.borrowerGender') }}</v-list-item-title>
                        <BaseSubtitle :loading="loading">{{ loan.client_gender ? $t(`loanDetails.gender.${loan.client_gender}`) : $t('loanDetails.investmentList.NA') }}</BaseSubtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('loanDetails.investmentList.borrowerAge') }}</v-list-item-title>
                        <BaseSubtitle :loading="loading">{{ loan.client_age || $t('loanDetails.investmentList.NA') }}</BaseSubtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-list>
            </template>
            <template #documents="{ loan }">
              <v-data-table
                :headers="documentListHeaders"
                :items="mergeDocuments(item, loan)"
                :loading="loading"
                disable-pagination
                hide-default-footer
              >
                <template #[`item.file`]="{ value }">
                  <v-btn :href="value" download class="my-3" dark>
                    {{ $t('loanDetails.investmentList.download') }}
                  </v-btn>
                </template>
              </v-data-table>
            </template>
          </LoanDetail>
        </v-expand-transition>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import Vue from 'vue'
import LoanDetail from "../../loans/components/LoanDetail"
import StartSelling from "../components/StartSelling"
import { intervalToDuration, formatDuration, startOfDay } from "date-fns"
import { ru, de, enGB, et, fr, es } from "date-fns/locale"

export default Vue.extend({
  name: "InvestmentList",
  components: {
    StartSelling,
    LoanDetail,
  },
  props: {
    count: { type: Number, required: true },
    page: { type: Number, required: true },
    size: { type: Number, required: true },
    items: { type: Array, required: true },
    loading: { type: Boolean, required: true },
    short: { type: Boolean, default: false },
    showSell: { type: Boolean, default: false },
    hide: { type: Boolean, default: false },  // this is for hiding investments in Allocation (Current)
  },
  data() {
    return {
      expanded: [],
      showing: {},
      menu: {},
    }
  },
  computed: {
    documentListHeaders() {
      return [
        {value: 'name',                     text: this.$t('loanDetails.investmentList.name'),                       align: "start", class: 'text-no-wrap', cellClass: 'text-no-wrap', sortable: false },
        {value: 'file',                     text: '',                                                               align: "end",   class: 'text-no-wrap', cellClass: 'text-no-wrap', sortable: false },
      ]
    },
    documentTypes() {
      return {
        'autoinvest_agreement': this.$t('documents.titles.autoinvestAgreement'),
        'claim_assignment':     this.$t('documents.titles.claimAssignment'    ),
        'claim_reassignment':   this.$t('documents.titles.claimReassignment'  ),
      }
    },
    headers() {
      let headers = []
      if (this.short) {
        headers = [
          { value: "loan.reference_number",    text: this.$t('loanDetails.investmentList.loanId'),                  align: "start", class: 'text-no-wrap', cellClass: 'text-no-wrap', sortable: false },
          { value: "investor_interest",        text: this.$t('loanDetails.investmentList.interestPercent'),         align: "start",                        cellClass: 'text-no-wrap', sortable: false },
          { value: "loan.period",              text: this.$t('loanDetails.investmentList.loanTerms'),               align: "start", class: 'text-no-wrap', cellClass: 'text-no-wrap', sortable: false },
          { value: "amount",                   text: this.$t('loanDetails.investmentList.amountToBeInvested'),      align: "start", class: 'text-no-wrap', cellClass: 'text-no-wrap', sortable: false },
        ]
      } else {
        headers = [
          { value: "loan.country",             text: "",                                                            align: "start", class: 'text-no-wrap', cellClass: 'text-no-wrap', sortable: false },
          { value: 'autoinvest_strategy',      text: this.$t('loanDetails.investmentList.strategyName'),            align: 'start',                        cellClass: 'text-no-wrap', sortable: false },
          { value: "loan.reference_number",    text: this.$t('loanDetails.investmentList.loanId'),                  align: "start", class: 'text-no-wrap', cellClass: 'text-no-wrap', sortable: false },
          { value: "start_date",               text: this.$t('loanDetails.investmentList.dateOfPurchase'),          align: "start",                        cellClass: 'text-no-wrap', sortable: false },
          { value: "investor_interest",        text: this.$t('loanDetails.investmentList.interestPercent'),         align: "start",                        cellClass: 'text-no-wrap', sortable: false },
          { value: "loan_amount",              text: this.$t('loanDetails.investmentList.loanAmount'),              align: "start", class: 'text-no-wrap', cellClass: 'text-no-wrap', sortable: false },
          { value: "amount",                   text: this.$t('loanDetails.investmentList.investedAmount'),          align: "start", class: 'text-no-wrap', cellClass: 'text-no-wrap', sortable: false },
          { value: "next_payment",             text: this.$t('loanDetails.investmentList.nextPayment'),             align: "start",                        cellClass: 'text-no-wrap', sortable: false },
          { value: "loan.period",              text: this.$t('loanDetails.investmentList.loanTerms'),               align: "start", class: 'text-no-wrap', cellClass: 'text-no-wrap', sortable: false },
          { value: "returned_principal",       text: this.$t('loanDetails.investmentList.returnedPrincipal'),       align: "start",                        cellClass: 'text-no-wrap', sortable: false },
          { value: "earned_interest",          text: this.$t('loanDetails.investmentList.earnedInterestAndFees'),   align: "start",                        cellClass: 'text-no-wrap', sortable: false },
          { value: "payments_in_recovery",     text: this.$t('loanDetails.investmentList.paymentsInRecovery'),      align: "start",                        cellClass: 'text-no-wrap', sortable: false },
          { value: "outstanding_principal",    text: this.$t('loanDetails.investmentList.outstandingPrincipal'),    align: "start",                        cellClass: 'text-no-wrap', sortable: false },
          {
            text: "",
            align: "center",
            sortable: false,
            value: "actions",
          },
        ]
      }
      if (!this.items.filter(investment => investment.autoinvest_strategy).length) {
        // all investments were done manually, hide the column
        headers = headers.filter(column => column.value != 'autoinvest_strategy')
      }
      return headers.filter(column => !column?.beta || this.$auth.user()?.is_beta_tester)
    },
  },
  methods: {
    investmentChanged() { this.$emit('investment') },
    startOfDay: startOfDay,
    pageChanged(value) { this.$emit('update:page', value) },
    sizeChanged(value) { this.$emit('update:size', value) },
    formattedPeriod(dateFrom, dateTo) {
      let timeBetweenDates = intervalToDuration({start: new Date(dateFrom), end: new Date(dateTo) })
      timeBetweenDates.months += timeBetweenDates.years * 12
      timeBetweenDates.years = 0
      let matches = formatDuration(
        timeBetweenDates,
        {
          format: ['months', 'days'],
          zero: true,
          locale: { 'et': et, 'ru': ru, 'en': enGB, 'de': de, 'fr': fr, 'es': es }[this.$i18n.locale],
        })
      if (matches === '') {
        return this.$t('loanDetails.investmentList.NA')
      }
      return matches.replace('üks', '1') // for some reason "1" in et locale is translated to "üks", this is a hack to override this behaviour
        .match(/\d+\s./g).map(item => item.replace(/\s/, '')).join(' ')
    },
    expandChange(row) {
      // Vuetify does not provide expand transitions. We have to
      // implement our own. See https://github.com/vuetifyjs/vuetify/issues/8197
      Vue.nextTick().then(() => {
        this.$set(this.showing, row.item.id, row.value)
      })
    },
    isInvestmentDisabled(item) {
      /**
        There are several cases when we should show investment as disabled:
        1. When loan was buybacked and principal repayed
        2. When investment was rejected by admin
        3. When investment was expired, but principal was paid
      */
      return parseFloat(item.outstanding_principal) <= 0 || item.status == 'rejected'
    },
    filterItems(items) {  // don't show all investments in Dashboard -> Allocation -> Current
      if (this.hide) {
        return items.filter(i => !this.isInvestmentDisabled(i))
      }
      return items
    },
    mergeDocuments(investment, loan) {
      const investmentDocuments = (investment?.documents || []).map(document => {
        return {
          file: document.file,
          name: this.documentTypes[document.type],
        }
      })
      const loanOriginatorDocuments = (loan?.loan_originator?.files || [])
      return [...investmentDocuments, ...loanOriginatorDocuments]
    },
    showSellButton(investment) {
      // We can only sell if it hasn't been sold yet
      // return !investment.secondary_market_entry
      return investment.can_be_sold
    },
  },
})
</script>

<style>
.investment-list tr.disabled {
  color: gray;
}
</style>
